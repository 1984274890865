import strings from "../../constants/strings";

const HomeScreen = () => {
  return (
    <div className="flex flex-col h-screen w-screen justify-center items-center bg-home-background bg-cover bg-center font-urbanist">
      <p className="text-white text-3xl font-bold">
        {strings.healthlyticsCanada}
      </p>
      <p className="text-white text-8xl mt-7 font-bold">{strings.comingSoon}</p>
    </div>
  );
};

export default HomeScreen;
