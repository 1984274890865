import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./screens/home/HomeScreen";
import UploadFileScreen from "./screens/uploadFile/UploadFileScreen";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="home" element={<HomeScreen />} />
        <Route path="upload" element={<UploadFileScreen />} />
      </Routes>
    </Router>
  );
}

export default App;
