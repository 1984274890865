import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { MdOutlineCloudUpload } from "react-icons/md";
import { OrbitProgress } from "react-loading-indicators";

import Modal from "@mui/material/Modal";
import React from "react";

const UploadFileScreen = () => {
  const [files, setFiles] = useState<File[]>([]);

  const onDrop = (acceptedFiles: File[]) => {
    setFiles([...files, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const uploadFiles = async () => {

    if (files.length === 0) {
      console.error("No files selected");
      alert("You must select at least one file to upload");
      return
    }

    setIsLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await fetch("https://dev-admin.ptlytics.ca/api/upload", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload files");
      }

      const result = await response.json();
      console.log("Files uploaded successfully:", result);
      setIsLoading(false);
      setFiles([]);
      handleOpenModal();
    } catch (error) {
      setIsLoading(false);
      console.error("Error uploading files:", error);
    }
  };

  return (
    <div className="flex flex-col items-center bg-blue-50 min-h-screen py-6">
      <h2 className="text-2xl font-semibold text-blue-500 mb-6">File Upload</h2>

      <div className="flex flex-row gap-20">
        <div className="flex flex-col">
          <div
            {...getRootProps()}
            className="w-80 h-64 border-2 border-dashed border-blue-500 flex items-center text-blue-500 mb-4 cursor-pointer"
          >
            <input {...getInputProps()} />

            <div className="flex flex-col items-center text-center">
              <MdOutlineCloudUpload className="text-blue-500" size={48} />
              <p>Drag & drop files here, or click to select files</p>
            </div>
          </div>

          <button
            onClick={() =>
              (
                document.querySelector('input[type="file"]') as HTMLInputElement
              )?.click()
            }
            className="bg-blue-400 text-white py-2 px-4 rounded"
          >
            Choose Files
          </button>
        </div>

        <div className="w-64">
          <h3 className="text-blue-500 text-lg">Selected Files:</h3>
          <ul className="list-disc">
            {files.map((file, index) => (
              <li key={index} className="text-blue-500">
                {file.name}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {isLoading && (
        <div className="flex flex-col gap-5 mt-10">
          <p className="text-blue-500">Uploading files...</p>
          <OrbitProgress color="#3b83f6" size="medium" text="" textColor="" />
        </div>
      )}

      {!isLoading && (
        <button
          onClick={() => {
            uploadFiles();
          }}
          className="bg-blue-500 text-white py-2 px-10 rounded mt-10"
        >
          Upload Files
        </button>
      )}

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 bg-slate-100 border-2 border-white shadow-lg p-4">
          <div className="flex flex-col gap-5">
            <h2 className="text-xl">Success</h2>
            <p>Files uploaded successfully</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UploadFileScreen;
